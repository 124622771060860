
// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-primary: mat.define-palette(mat.$indigo-palette);
$angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$angular-theme: mat.define-light-theme((
  color: (
    primary: $angular-primary,
    accent: $angular-accent,
    warn: $angular-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($angular-theme);

/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&family=Open+Sans&display=swap');
@import '~mdb-ui-kit/css/mdb.min.css';
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import '../node_modules/ngx-org-chart/_theming.scss';

.navbar-brand,
h1,
.list-header {
    font-family: Heebo, Arial, Helvetica, sans-serif !important;
    font-weight: bold;
}

.nav-item {
    font-family: Heebo, Arial, Helvetica, sans-serif !important;
    font-size: 0.95rem;
    border-color: black;
    border-left: 10px;
    border-right: 10px;
}

body * {
    font-family: Heebo, Arial, Helvetica, sans-serif;
    scroll-behavior: smooth;
}

.carousel {
    width: 100%;
    height: auto;
}

.row {
    --mdb-gutter-x: .8rem !important;
}

.flex-layout{
    flex-direction: row;
}

.width-layout{
    width: 75% !important;
}

.width-layout-reverse{
    width:100% !important;
}

@media screen and (min-width: 1200px) { 
    .xl-container-item{        
        margin-right: 1rem!important;
        margin-left: 1rem!important;
    }

    // .xl-item{

    // }
    router-outlet {
        width: 600px;
    }
    .reg-icon {
        height: 120px;
        width: 120px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .lg-container-item{        
        margin-right: 1rem!important;
        margin-left: 1rem!important;
    }

    .lg-item{      
        display: flex;
        align-items: center;
        flex-direction: column;  
    }

    router-outlet {
        min-width: 496px;
        max-width: 600px;
    }
    .table-documents {
        overflow-x: auto;
        min-width: 496px;
        max-width: 1200px;
    }
    .reg-icon {
        height: 96px;
        width: 96px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .md-item{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    
    .flex-layout{
        flex-direction: column-reverse;
    }
    .width-layout{
        width: 100% !important;
    }
    .width-layout-reverse{
        width:50% !important;
    }
    router-outlet {
        min-width: 360px;
        max-width: 496px;
    }
    h4 .list-header,
    .nav-item {
        font-size: smaller;
    }
    .table-documents {
        overflow-x: auto;
        min-width: 360px;
        max-width: 992px;
    }
    .reg-icon {
        height: 96px;
        width: 96px;
    }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
    .sm-item{
        display: flex;
        align-items: center;
        flex-direction: column; 
        padding: 10px;
    }

    .flex-layout{
        flex-direction: column-reverse;
    }
    .width-layout{
        width: 100% !important;
    }
    .width-layout-reverse{
        width:50% !important;
    }
    router-outlet {
        min-width: 240px;
        max-width: 360px;
    }
    h4 .list-header,
    .nav-item {
        font-size: smaller;
    }
    .table-documents {
        overflow-x: auto;
        min-width: 240px;
        max-width: 360px;
    }
    .reg-icon {
        height: 96px;
        width: 96px;
    }
}

@media screen and (min-width: 0) and (max-width: 480px) {
    .xs-item{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 10px;
    }
    .flex-layout{
        flex-direction: column-reverse;
    }
    .width-layout{
        width: 100% !important;
    }
    .width-layout-reverse{
        width:50% !important;
    }
    router-outlet {
        min-width: 160px;
        max-width: 240px;
    }
    h4 .list-header,
    .nav-item {
        font-size: smaller;
    }
    .table-documents {
        overflow-x: auto;
        min-width: 160px;
        max-width: 480px;
    }
    .reg-icon {
        height: 96px;
        width: 96px;
    }
}

a.nav-link:hover,
a.nav-link:focus
 {
    //text-indent: 0.2rem;
    //font-weight: bold;
    overflow-x: auto;
}

ul a.btn:hover {
    color: #Fff !important;
    font-weight: bold;
    font-size: large;
    padding: auto;
    overflow-x: auto;
}

.content {
    flex-direction: column;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0.2);
    //background-color: #FBFAF5;
}

footer {
    margin-top: auto !important;
    padding-bottom: 0 !important;
}

#myBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: red;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
}

#myBtn:hover {
    background-color: #555;
}


/*******************************
 * Theme Elements by User Type *
 *******************************/


/* General Element Properties */

.theme-default,
.theme-agency,
.theme-arta,

button.theme-default-btn:hover,
button.theme-agency-btn:hover,
button.theme-arta-btn:hover,

button.theme-default-btn.active,
button.theme-agency-btn.active,
button.theme-arta-btn.active,

button.theme-default-btn-inverted,
button.theme-agency-btn-inverted,
button.theme-arta-btn-inverted {
    color: white !important;
}

button.theme-default-btn,
button.theme-agency-btn,
button.theme-arta-btn,
button.theme-warning-btn,

button.theme-default-btn-inverted:hover,
button.theme-agency-btn-inverted:hover,
button.theme-arta-btn-inverted:hover,
button.theme-warning-btn-inverted:hover,

button.theme-default-btn-inverted.active,
button.theme-agency-btn-inverted.active,
button.theme-arta-btn-inverted.active,
button.theme-warning-btn-inverted.active {
    background-color: transparent;
    color: black !important;
}


/* Normal Theme Elements */

.theme-registration,
button.theme-registration-btn:hover,
button.theme-registration-btn.active,
button.theme-registration-btn-inverted {
   //background-color: navy;
   background-image: url("./assets/Assets/pbris_header/arta-header-gray.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.theme-default,
button.theme-default-btn:hover,
button.theme-default-btn.active,
button.theme-default-btn-inverted {
   //background-color: navy;
   background-image: url("./assets/Assets/pbris_header/default-header.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.theme-default-text {
    color: navy;
}

.theme-default-border {
    border-color: navy;
}


/* Agency Theme Elements */

.theme-agency,
button.theme-agency-btn:hover,
button.theme-agency-btn.active,
button.theme-agency-btn-inverted {
    //background-color: firebrick;
    background-image: url("./assets/Assets/pbris_header/agency-header.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.theme-agency-text {
    color: firebrick;
}

.theme-agency-border {
    border-color: firebrick;
}


/* ARTA Theme Elements */

.theme-arta,
button.theme-arta-btn:hover,
button.theme-arta-btn.active,
button.theme-arta-btn-inverted {
    //background-color: black;
    background-image: url("./assets/Assets/pbris_header/arta-header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.theme-arta-text {
    color: black;
}

.theme-arta-border {
    border-color: black;
}

.bg-gray {
    background-color: #F1F1F1;
}

.theme-warning,
button.theme-warning-btn:hover,
button.theme-warning-btn.active,
button.theme-warning-btn-inverted {
    background-color: #FFA900;
}

.theme-warning-text {
    color: black;
}

.theme-warning-border {
    border-color: #FFA900;
}

.theme-subheader,
button.theme-subheader-btn:hover,
button.theme-subheader-btn.active,
button.theme-subheader-btn-inverted {
    background-color: #FFF2CC;
}

.theme-subheader-text {
    color: black;
}

.theme-subheader-border {
    border-color: #FFF2CC;
}

.custom-dialog-container .mat-dialog-container {
    background-color: rgb(33, 33, 33) !important;
    color: white !important;
    // padding: 0 !important;
    padding: inherit 0;
    height: max-content !important;
    .mat-dialog-content{
        margin: 0 !important;
        padding-bottom: 2em;
        // padding: 0 !important;
    }
}

/**/


/**/


/**/


/**/


/**/


/**/


/**/


/**/


/**/


/**/


/**/


/**/


/**/


/**/

html,
body {
    height: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.requirement-table{
    width: 90%;
    th, td{
        border:none;
        padding-left: 0.5em;
    }
}
.req-details-header{
    font-weight: bold;
    padding: 0;
    margin: 0;
}


///tree view
/// 
/// 
.tree, .tree ul {
    margin:0;
    padding:0;
    list-style:none
}
.tree ul {
    margin-left:1em;
    position:relative
}
.tree ul ul {
    margin-left:.5em
}
.tree ul:before {
    content:"";
    display:block;
    width:0;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    border-left:1px solid
}
.tree li {
    margin:0;
    padding:0 1em;
    line-height:2em;
    color:#369;
    font-weight:normal;
    position:relative
}
.tree ul li:before {
    content:"";
    display:block;
    width:10px;
    height:0;
    border-top:1px solid;
    margin-top:-1px;
    position:absolute;
    top:1em;
    left:0
}
.tree ul li:last-child:before {
    background:#f4f4f4;
    height:auto;
    top:1em;
    bottom:0
}
.indicator {
    margin-right:5px;
}
.tree li a {
    text-decoration: none;
    color:#369;
}
.tree li button, .tree li button:active, .tree li button:focus {
    text-decoration: none;
    color:#369;
    border:none;
    background:transparent;
    margin:0px 0px 0px 0px;
    padding:0px 0px 0px 0px;
    outline: 0;
}


